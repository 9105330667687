
export const getPorcentaje = (oldPrice, newPrice) => {
  let arr = { por: 0, msg: 'Permanece igual', val: 0, price: 0, color: '' }
  if(newPrice > oldPrice){
    let total = oldPrice / newPrice * -100 + 100
    let price = parseFloat((oldPrice * total / 100).toFixed(2))
    arr = { por: parseFloat(total.toFixed(2)), msg: 'Aumentó', val: 1, price, color: 'text-green' }
  }else if(oldPrice > newPrice){
    let total = newPrice / oldPrice * -100 + 100
    let price = parseFloat((newPrice * total / 100).toFixed(2))
    arr = { por: parseFloat(total.toFixed(2)), msg: 'Disminuyó', val: 2, price, color: 'text-danger' }
  }
  return arr;
}

export const filtroNum = (e) => {
  const c = parseFloat(e).toString().split('.');
  const f = c.length >= 2 ? (c[1].length >= 8 ? 8 : c[1].length) : 2;
  const a = parseFloat(e).toFixed(f).replace('.', ',').split(',');
  return a[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ',' + a[1];
}

export const formatDate = (v) => {
  return v.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
}