import React from 'react';
import { NavLink } from 'react-router-dom';
import insta from '../assets/svg/insta.svg';
import twitter from '../assets/svg/twitter.svg';
import email from '../assets/svg/mail.svg';
import logoG from '../assets/img/myDollarlogo1-min.webp';

const Footer = () => {
  return(
    <footer>
        <div className="container">          
          <div className="row text-center d-flex pt-5 mb-2 logo-footer text-md-left">  
            <div className="col-md-12">     
              <NavLink to="/" exact> <img src={logoG} className="d-inline-block align-top logo" alt="MyDollar Logo"/> </NavLink>
            </div>         
          </div>       
          <div className="row py-3 text-center text-md-right">

            <hr className="hr-footer"/>
            <div className="order-2 order-lg-1 order-md-1 order-xl-1 col-12 col-md-6 text-md-left">
              <div className="footer-copyright pt-4">© 2020 MyDollar | All Rights Reserved | <NavLink to="/privacy-policy"> Política de Privacidad</NavLink>
              </div>
            </div>
            <div className="order-1 pt-4 order-md-2 order-lg-2 order-xl-2 col-12 col-md-6">
                <a href="https://www.instagram.com/appmydollar" title="Instagram" target="_blank" rel="noopener noreferrer" className="ins-ic">
                  <img className="icon-social ml-4 mr-4" alt="Instagram" src={insta} />
                </a>             
                <a href="https://www.twitter.com/appmydollar" title="Twitter" target="_blank" rel="noopener noreferrer" className="tw-ic">
                  <img className="icon-social mr-4" alt="Twitter" src={twitter} />
                </a>              
                <a href="mailto:support@appmydollar.com" title="Email" className="pin-ic">
                  <img className="icon-social mr-4" alt="Email" src={email} />
                </a>
            </div>
          </div>
        </div>   
      </footer>
  )
}
export default Footer;