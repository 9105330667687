import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import logo from '../assets/img/myDollarlogo2-min.webp';

const Navigation = ({history, Link}) => {
	return(
		<nav className="navbar navbar-header bg-transparent">
		  <NavLink to="/" exact className="navbar-brand" title="MyDollar"><img src={logo} className="d-inline-block align-top logo" alt="MyDollar Logo"/></NavLink>
		  <form className="form-inline">
		    <Link to="descarga-app" spy={true} hashSpy={true} smooth={true} duration={500} className="btn btn-outline-light btn-md btn-round" type="button">Descargar APP</Link>
		  </form>
		</nav>
	)
}
export default withRouter(Navigation);