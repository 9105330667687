import React from 'react';
import dolar from '../assets/svg/dolarCircle.svg';
import euro from '../assets/svg/euroCircle.svg';

import { getPorcentaje, filtroNum } from '../functions'

const Tabla = ({getCoin, state}) => {

  return(
    <section className="indicadores">
      <div className="ondita ondablanca"></div>
      <div className="container contenido">
        <div className="row text-center">
          <div className="col-12 mb-5">
            <h2 className="title title-black bold-font">Indicadores</h2> 
            <hr className="hr-green"/>
            <p className="parrafo-black">Los precios <span className="bold-font">Promedio del Dólar (USD) y Euro (EUR)*</span> se calculan según las cotizaciones y tasas de diferentes entidades de importancia y mercados electrónicos dentro y fuera de Venezuela.</p>
          </div>
          <div className="col-12 mb-4">
            <div className="row table-scroll">
              <div className="col-12 table shadow-sm">
                <div className="row row-table medium-font first-row">
                  <div className="col-1">Coin</div>
                  <div className="col-4">Indicador</div>
                  <div className="col-4">Precio</div>
                  <div className="col-3">Cambio</div>
                </div>
                {
                  state.loading === false ?
                    state.arrayBody.map(e => {
                      const nameCoin = getCoin.body.filter(elem => (elem.id === parseInt(e.i)))[0].name
                      const { color, por, val, price } = getPorcentaje(e.o, e.n)
                      return(
                        <div key={e.i}>
                          <div className="w-100"></div>
                          <div className="row row-table medium-font align-items-center">
                            <div className="col-1"><img className="icon-coin" alt="dolar" src={getCoin.codigo === 'USD' ? dolar : euro} /></div>
                            <div className="col-4">{nameCoin}</div>
                            <div className="col-4"><small>VES</small> {filtroNum(e.n)}</div>
                            <div className={`col-3 ${color}`}>{val === 1 ? '+' : null}{val === 2 ? '-' : null}{filtroNum(price)} ({filtroNum(por)}%)</div>
                          </div>
                        </div>
                      );
                    })
                  : null 
                }
              </div>
            </div>
          </div>
          <div className="col-12 mb-4 text-left"><small><b>*Nota:</b> Nosotros no establecemos ningún precio contenido en esta página web. Las cotizaciones presentes en esta página web son establecidas por las entidades o mercados electrónicos listados anteriormente.  La decisión que tomen los usuarios sobre la información que suministramos no tiene ninguna relación con nosotros. </small>
          </div>
        </div>  
      </div>
    </section>
  )
}
export default Tabla;