import { pushData } from '../index'
import { Localhost } from '../configServer'

const getUrl = (e) => {
	let value = '';
	switch(e){
		case 1: value = 'routes/rates/requestDolar.php'; break;
		case 2: value = 'routes/rates/requestEuro.php'; break;
		default: value = 'routes/rates/requestDolar.php'; break;
	}
	return value;
}

async function requestPrice(coinSelected){
	const Url =  Localhost + getUrl(coinSelected);
	const getForm = { }
	return pushData(getForm, Url); // arrayBody, arrayHeader, h, f
}


export {
	requestPrice
}