import React from 'react';

const DescargaApp = () => {
  return(
    <section id="descarga-app" className="descarga-app medium-font">
        <div className="ondaApp"></div>
        <div className="container-fluid app-row">
          <div className="row text-left align-items-center">
            <div className="order-2 order-lg-1 order-xl-1 order-md-1 col-sm-12 col-md-6 col-lg-6 mb-5 movil-img"><img alt='MyDollar Rates App' src={require("../assets/img/movil-min.webp")}/>
            </div>
            <div className="pl-sm-5 pr-sm-5 pr-lg-5 pr-xl-5 order-1 order-xl-2 order-lg-2 order-md-2 col-sm-12 col-md-6 col-lg-6 cont-app">
              <h2 className="title title-app bold-font">Descarga la Primera <br/>App Monitor de Precios <br/>las 24 horas al día.</h2> 
              <hr className="hr-white"/>
              <p className="parrafo"><span className="bold-font">MyDollar Rates</span> es la primera aplicación de consulta y monitor de precios del Dólar, Euro, Divisas y Criptomonedas con la que podrás mantenerte informado, donde quiera que estés, <span className="bold-font">las 24 horas al día y los 7 días de la semana.</span></p>
              <div className="row">
                <div className="col-12 col-md-6 mb-5 google-img">
                  <a target="_blank" rel="noopener noreferrer" title="Descarga la APP en Google Play" href="https://play.google.com/store/apps/details?id=com.mydollarrates"><img alt="Disponible en Google Play" src={require("../assets/img/google-play-badge.webp")}/></a>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </section>
  )
}
export default DescargaApp;