const getTextRandom = (Size) => {
	let str = '';
	let ref = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	for (var i=0; i<Size; i++) { str += ref.charAt(Math.floor(Math.random()*ref.length)) }
	return str;
}
const getGMT = () => {
	var timeString = new Date().toTimeString();
	var timeL = timeString.substring(12,15);
	var timeR = timeString.substring(15,17).replace('15', '25').replace('30', '50');
	return parseInt(timeL +''+ timeR) / 100;
}
const getTokenApp = () => {
	let timeApp = Math.round(new Date().getTime() / 1000) + (getGMT() * 3600);
	let totalTime = btoa(timeApp);
	let dateGMT = btoa(getGMT());
	return getTextRandom(14) + '' + totalTime.replace('==', 'i0') + '' + getTextRandom(4) + '.' + getTextRandom(4) + '' + dateGMT;
}

const getStructureLogin = (getForm) => {
	const tokenApp = getTokenApp();
	return {
		method: 'post',
		body: JSON.stringify({
			'Authorization': 'Bearer ' + tokenApp,
		    'data': getForm
	  	})
  	}
}
const ERROR_CODE = [
] 

const WARNING_CODE = [
] 

const getError = async (idError) => {
	const getError = ERROR_CODE.filter((element) => { return element.id === idError });
	alert(getError[0].desc);
}

const getWarning = async (idWarning) => {
	const getWarning = WARNING_CODE.filter((element) => { return element.id === idWarning });
	alert(getWarning[0].desc);
}

export async function pushData(dataForm, getUrl, updateAuth){
	const obj = await getStructureLogin(dataForm);
	return fetch(getUrl, obj)
	.then(function(response){ 
		return response.json(); 
	})
	.then(function(data){
		let valueReturn = null;
		switch(data.code){
			case 0: valueReturn = data.body; break;
			case 50: getWarning(data.warning); valueReturn = data.body; break;
			case 500: valueReturn = false; break;
			default: getError(data.code); valueReturn = false; break;
		}
		return valueReturn;
	})
	.catch((error) => {
		console.log(error);
      	return false
    });
}