import React, {useEffect, useState, useLayoutEffect} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Link, scrollSpy } from 'react-scroll';
import { requestPrice } from './fetch/rates/'
import Header from './components/header';
import Tabla from './components/tabla';
import Footer from './components/footer';
import Privacidad from './components/privacidad';
import DescargaApp from './components/descargaApp';
import loader from './assets/img/loader.webp';
import shadow from './assets/img/shadow.webp';
import './assets/css/App.css';

const JSON_ALL = {
  dolar: {
    codigo: "USD",
    coin: "Dólar",
    nameCoin: "Dólar Estadounidense",
    header: [
      {id: 1, name: 'Dolar Bdv'},
      {id: 2, name: 'Dolar Promedio'},
    ],
    body: [
      {id: 1, name: 'Dolar Today'},
      {id: 2, name: 'Local Bitcoin'},
      {id: 3, name: 'Yadio.io'},
      {id: 4, name: 'Airtm'},
      {id: 5, name: 'Bolivar Cucuta'},
      {id: 6, name: 'MoviCambio'},
      {id: 7, name: 'Mkambio'}
      {id: 10, name: 'Binance'}
      {id: 11, name: 'Reserve'}
      {id: 12, name: 'Cambios R&A'}
    ]
  },
  euro: {
    codigo: "EUR",
    coin: "Euro",
    nameCoin: "Euro Europeo",
    header: [
      {id: 1, name: 'Dolar Bdv'},
      {id: 2, name: 'Dolar Promedio'},
    ],
    body: [
      {id: 1, name: 'Dolar Today'},
      {id: 2, name: 'Local Bitcoin'},
      {id: 3, name: 'Yadio.io'},
      {id: 4, name: 'Airtm'},
      {id: 5, name: 'Bolivar Cucuta'},
      {id: 6, name: 'MoviCambio'}
      {id: 9, name: 'Binance'}
      {id: 10, name: 'Reserve'}
      {id: 11, name: 'Cambios R&A'}
    ]
  }
}

const ARRAY_HOR = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
]

 const App = () => { 
    const [state, setState] = useState({
      showlist: false,
      showClass: "dropdown-menu",
      coinSelected: 1,
      arrayBody: [],
      arrayHeader: [ { n: 0 }, { n: 0 } ],
      hora: '',
      fecha: '',
      loading: false,
      loader: true,
      button: false
    });  

    let scrollState = false;

    useEffect(() => {
      scrollSpy.update();
      getPrice(1);
    },[]);
    
    useLayoutEffect(() => {
      window.addEventListener('scroll', handleScroll, true)
      return () => window.removeEventListener('scroll', handleScroll)
    }, []);

    const handleScroll = () => {
      let lastScrollY = window.scrollY;
      if(lastScrollY >= (window.innerHeight / 2) && !scrollState){
          setState(e => {return { ...e, button: true}});
          scrollState = true;
      }

      if(lastScrollY < (window.innerHeight / 2) && scrollState){
        setState(e => {return { ...e, button: false}});
        scrollState = false;
      }
    }

    const openList = () => { 
      setState(e => {
        return { 
          ...e,
          showlist: !e.showlist,
          showClass: e.showlist ? "dropdown-menu" : "dropdown-menu d-block"
        }
      });
    }

    const changeList = async (idcoin) => {
      if(idcoin === state.coinSelected) return;
      await setState(e => {
        return {
          ...e, 
          showlist: !e.showlist,
          showClass: e.showlist ? "dropdown-menu" : "dropdown-menu d-block",
          loading: true
        }
      });
      getPrice(idcoin);
    }

    const getPrice = async (idcoin) => {
      const fetch = await requestPrice(idcoin);
      if(fetch){
        const { arrayBody, arrayHeader, h, f } = fetch;
        await setState(e => {
          return { 
            ...e, 
            arrayBody, 
            arrayHeader, 
            coinSelected: idcoin,
            hora: ARRAY_HOR[parseInt(h) - 1],
            fecha: f,
            loading: false,
            loader: false
          }
        });
        // TU ANIMACIÓN
        return;
      }
      await setState({...state, loading: false, loader: false });
      return;
    }

    const getCoin = state.coinSelected === 1 ? JSON_ALL.dolar : JSON_ALL.euro;

    return (
      <Router>      
        <Switch>
          <Route exact path="/" render={() => {
            return (
              <div className="App">
                {
                  state.loader ?
                  <div className="loader">
                    <img src={loader} className="logo-loader" alt="MyDollar Loader"/>
                    <img src={shadow} className="logo-loader-shadow" alt="MyDollar Loader"/>
                  </div>
                  : null
                }
                {
                  state.loading ?
                  <div className="lds-circle"><div className={state.coinSelected === 1 ? 'circle-euro' : 'circle-dolar'} ></div></div>
                  : null
                }
                <Header getCoin={getCoin} state={state} Link={Link} changeList={changeList} openList={openList} />
                <Tabla getCoin={getCoin} state={state} />
                <DescargaApp />
                <Footer />
                { state.button ? <Link to="top" spy={true} hashSpy={true} smooth={true} duration={500} className="go-up"></Link> : null }
              </div>
            )
          }} />
          <Route path="/privacy-policy" component={ Privacidad } />        
          <Route render={() => <h1>Error 404</h1>} />
        </Switch>
      </Router>   
    );
}

export default App;
