import React from 'react';
import Navigation from './navigation';
import twitterS from '../assets/svg/twitterS.svg';
import gmail from '../assets/svg/gmail.svg';
import facebook from '../assets/svg/facebook.svg';
import whatsapp from '../assets/svg/whatsapp.svg';
import telegram from '../assets/svg/telegram.svg';
import linkedin from '../assets/svg/linkedin.svg';
import up from '../assets/svg/uparrow.svg';
import down from '../assets/svg/downarrow.svg';
import share from '../assets/svg/share.svg';
import clock from '../assets/svg/clock.svg';
import dolar from '../assets/svg/dolarCircle.svg';
import euro from '../assets/svg/euroCircle.svg';

import { getPorcentaje, filtroNum, formatDate } from '../functions'

const Header = ({getCoin, state, Link, changeList, openList}) => {

  const { por: porHeader, val: valHeader, price: priceHeader } = getPorcentaje(state.arrayHeader[1].o, state.arrayHeader[1].n);

  let textShare=`MyDollar Rates - El precio del ${getCoin.coin} Promedio hoy ${formatDate(state.fecha)} a las ${state.hora} es Bs.${filtroNum(state.arrayHeader[1].n)} y del ${getCoin.coin} BCV es Bs.${filtroNum(state.arrayHeader[0].n)} #appmydollar #dolarvzla`;

  const shareIt = () => {
    if (navigator.share) {
      navigator.share({
        title: 'MyDollar Rates',
        text: textShare,
        url: 'https://rates.appmydollar.com/'
      })
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }
  
  return(
    <header className="medium-font" id="top">
      <Navigation Link={Link}/>   
      <div className=" flex justify-content-center">     
      <div className="container">
        <div className="row text-center">
          <div className="col-12 mb-4 pr-5 pl-5 text-right drop-col">
            <div className="btn-group">
              <button className="btn btn-white btn-lg btn-cod"><span className="drop-coin">{getCoin.codigo}</span></button>
              <button onClick={openList} className="btn btn-white btn-w-r dropdown-toggle dropdown-toggle-split btn-lg">
                <span className="name-coin">{getCoin.nameCoin}</span>
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className={state.showClass}>
                <button onClick={() => changeList(1)} className="dropdown-item"><img className="icon-coin" alt="dolar" src={dolar} /> Dólar (USD)</button>
                <div className="dropdown-divider"></div>
                <button onClick={() => changeList(2)}  className="dropdown-item"><img className="icon-coin" alt="euro" src={euro} /> Euro (EUR)</button>
              </div>                          
            </div>

          </div>
          <div className="col-12 mb-4 font-medio">Actualizado: {state.hora} (-04:00 UTC)</div>
          <div className="col-6 border-right bold-font bold-big"><small>VES</small> { filtroNum(state.arrayHeader[0].n) }</div>
          <div className="col-6 bold-font bold-big"><small>VES</small> { filtroNum(state.arrayHeader[1].n) }</div>
          <div className="w-100"></div>
          <div className="col-6 border-right regular-font regular-big">BCV</div>
          <div className="col-6 regular-font regular-big">PROMEDIO</div>
          <div className="col-12 mt-4 mb-4">
            <img className="icon-header clock" alt="tiempo" src={clock} />
            Hace 24 horas: 
            <span className="bold-font"> {filtroNum(state.arrayHeader[1].o)} </span> 
            VES/{getCoin.codigo}  
            <span className="bold-font"> { valHeader === 1 ? '+'+filtroNum(priceHeader) : null } { valHeader === 2 ? '-'+filtroNum(priceHeader) : null } </span> 
            VES 
            <span className="bold-font"> { valHeader === 1 ? '+'+filtroNum(porHeader) : null } { valHeader === 2 ? '-'+filtroNum(porHeader) : null }% </span>
            { valHeader === 1 ? <img className="icon-header up" alt="up" src={up} /> : null } 
            { valHeader === 2 ? <img className="icon-header up" alt="down" src={down} /> : null } 
          </div>
          <div className="col-12 col-md-12 mb-4 font-medio">
            {
              navigator.share ?
              <button className="btn btn-outline-light btn-sm btn-round btn-medio" type="button" onClick={shareIt}> <img className="icon-header share-icon" alt="Comparte" src={share} /> Comparte</button>
              :
              <div>
                <div className="share">Compartir: </div>
                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A//rates.appmydollar.com" title="Comparte en Facebook" target="_blank" rel="noopener noreferrer"><img className="icon-social mr-2" alt="Facebook" src={facebook} /></a>

                <a href={`https://twitter.com/share?url=https%3A%2F%2Frates.appmydollar.com&amp;text=El+precio+del+${getCoin.coin}+Promedio+hoy+${formatDate(state.fecha)}+a+las+${state.hora}+es+Bs.${filtroNum(state.arrayHeader[1].n)}+y+del+${getCoin.coin}+BCV+es+Bs.${filtroNum(state.arrayHeader[0].n)}+%23appmydollar+%23dolarvzla&amp;via=appmydollar`} title="Comparte en Twitter" target="_blank" rel="noopener noreferrer" ><img className="icon-social mr-2" alt="Twitter" src={twitterS} /></a>

                <a href={`https://wa.me/?text=MyDollar Rates - El precio del ${getCoin.coin} Promedio hoy ${formatDate(state.fecha)} a las ${state.hora} es Bs.${filtroNum(state.arrayHeader[1].n)} y del ${getCoin.coin} BCV es Bs.${filtroNum(state.arrayHeader[0].n)} %23appmydollar %23dolarvzla https%3A%2F%2Frates.appmydollar.com`} target="_blank" rel="noopener noreferrer" title="Comparte en Whatsapp" ><img className="icon-social mr-2" alt="Whatsapp" src={whatsapp} /></a>

                <a href={`https://telegram.me/share/msg_url?url=https%3A%2F%2Frates.appmydollar.com&text=MyDollar%20Rates%20-%20El%20precio%20del%20${getCoin.coin}%20Promedio%20hoy%20${formatDate(state.fecha)}%20a%20las%20${state.hora}%20es%20Bs.${filtroNum(state.arrayHeader[1].n)}%20y%20del%20${getCoin.coin}%20BCV%20es%20Bs.${filtroNum(state.arrayHeader[0].n)} %23appmydollar %23dolarvzla`} target="_blank" rel="noopener noreferrer" title="Comparte en Telegram"><img className="icon-social mr-2" alt="Telegram" src={telegram} /></a>

                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A//rates.appmydollar.com/&title=MyDollar%20Rates&summary=El%20precio%20del%20${getCoin.coin}%20Promedio%20hoy%20${formatDate(state.fecha)}%20a%20las%20${state.hora}%20es%20Bs.${filtroNum(state.arrayHeader[1].n)}%20y%20del%20${getCoin.coin}%20BCV%20es%20Bs.${filtroNum(state.arrayHeader[0].n)}%20%23appmydollar%0A%23dolarvzla%20&source=`} target="_blank" rel="noopener noreferrer" title="Comparte en LinkedIn"><img className="icon-social mr-2" alt="LinkedIn" src={linkedin} /></a>

                <a href={`https://mail.google.com/mail/u/0/?view=cm&body=MyDollar%20Rates%20-%20El%20precio%20del%20${getCoin.coin}%20Promedio%20hoy%20${formatDate(state.fecha)}%20a%20las%20${state.hora}%20es%20Bs.${filtroNum(state.arrayHeader[1].n)}%20y%20del%20${getCoin.coin}%20BCV%20es%20Bs.${filtroNum(state.arrayHeader[0].n)}%20%23appmydollar%20%23dolarvzla%20https%3A//rates.appmydollar.com`} target="_blank" rel="noopener noreferrer" title="Comparte en Gmail"><img className="icon-social last-icon" alt="Gmail" src={gmail} /> </a>
              </div>                
            }
            
          </div>                
        </div>
      </div>
      </div>
    </header>
  )
}
export default Header;