import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from './footer';
import logo from '../assets/img/myDollarlogo2-min.webp';

const Privacidad = () => {
	return(
		<div>
			<nav className="navbar navbar-green bg-green sticky-top">
			  <NavLink to="/#top" exact className="navbar-brand" title="MyDollar"><img src={logo} className="d-inline-block align-top logo" alt="MyDollar Logo"/></NavLink>
			  <form className="form-inline">
			    <a href="https://play.google.com/store/apps/details?id=com.mydollarrates" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-md btn-round" type="button">Descargar APP</a>
			  </form>
			</nav>
			<section className="privacidad">
			  <div className="container">
			    <div className="row text-left">
			      <div className="col-12 mb-5">
			        <h2 className="title-black bold-font title-h2">Política de Privacidad de Datos de MyDollar Rates</h2> 
			        <span>Última actualización: 18/01/2020</span>
			        <p className="parrafo-black mt-5">Para Darpsoft es muy importante la protección de datos así como la privacidad de sus usuarios. Para la recopilación y el uso de tus datos personales cumpliremos las disposiciones legales de la ley de protección de datos aplicable. Con la presente política de privacidad, Darpsoft informa sobre el tratamiento de datos personales en la aplicación móvil.</p>

			        <h4 className="bold-font subtitle">1. Ámbito de aplicación</h4>

					<p className="parrafo-black">La presente política de privacidad es aplicable a la aplicación MyDollar Rates disponible en la tienda de aplicaciones Google Play con todas sus ofertas online, sus contenidos, funciones y servicios, incluso en los casos en los que contengan ofertas temporales, breves o relacionadas con un determinado evento.</p>

					<h4 className="bold-font subtitle">2. Tratamiento de datos personales</h4>

					<p className="parrafo-black">MyDollar Rates no almacena datos personales en la aplicación. Los datos personales son datos sobre circunstancias materiales o personales de una persona física identificada o identificable. Entre ellos encontramos, especialmente, datos que permiten deducir tu identidad, por ejemplo, tu nombre, dirección, correo electrónico.</p>

					<p className="parrafo-black">Los datos estadísticos que recopilamos, por ejemplo, cuando visitas y utilizas nuestras aplicaciones o páginas web, y que no permiten deducir directamente tu identidad, no están dentro de los denominados «datos personales».</p>

					<h4 className="bold-font subtitle">3. Transferencia de datos personales</h4>

					<p className="parrafo-black">En principio MyDollar Rates no almacena ni transfiere tus datos personales a terceros. Solo se produce una transferencia de datos recopilados a terceros si es necesario para proporcionar la funcionalidad técnica de la aplicación o si existe otra base legal para la transferencia de datos.</p>

					<p className="parrafo-black">Solo los datos anónimos agregados se transmiten periódicamente a servicios externos, por ejemplo, Google Analytics y otros servicios de Google LLC., para ayudarnos a mejorar la aplicación y nuestro servicio. Compartiremos tu información con terceros solo de la manera que se describe en esta declaración de privacidad.</p>

					<h4 className="bold-font subtitle">4. Procedimiento de análisis</h4>

					<p className="parrafo-black">Con el fin de mejorar nuestra aplicación, utilizamos diferentes tecnologías para analizar el comportamiento de uso y evaluar los datos obtenidos. Este proceso es anónimo o se basa en seudónimos. En el siguiente apartado nos gustaría detallarte en qué consisten estas tecnologías y presentarte a los proveedores que las utilizan.</p>
					
					<p className="parrafo-black">MyDollar Rates utiliza Google Analytics y Google Publisher Tag (GPT), ambos son servicios de analítica web y de aplicaciones de Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, EE.UU. («Google»). Estos servicios pueden recopilar cierta información de forma automática, que incluye, entre otros, tu ID de publicidad de Google, el tipo y la versión de tu dispositivo, tu sistema operativo móvil, la actividad de navegación, los navegadores de Internet que usas e información sobre la forma en que usas la aplicación, la ubicación y otros datos técnicos relacionados con tu dispositivo, para proporcionar anuncios. Esta aplicación no recopila información precisa sobre la ubicación de tu dispositivo móvil.</p>

					<p className="parrafo-black">Para conocer el propósito y el alcance de la recopilación de datos de Google, así como el posterior procesamiento y uso de datos, además de tus derechos y las opciones de configuración para proteger tu privacidad, consulta la política de privacidad de Google.</p>

					<h4 className="bold-font subtitle">5. Notificaciones PUSH</h4>

					<p className="parrafo-black">MyDollar Rates utiliza Firebase para el servicio de notificaciones push, un servicio de Google LLC. Para conocer el propósito y el alcance de la recopilación de datos de Firebase, así como el posterior procesamiento y uso de datos, además de tus derechos y las opciones de configuración para proteger tu privacidad, consulta la política de privacidad de Firebase.</p>

					<h4 className="bold-font subtitle">6. Tu consentimiento</h4>

					<p className="parrafo-black">Al usar la aplicación MyDollar Rates, aceptas y autorizas que procesemos tu información tal como se establece en esta Política de privacidad ahora y tal como la enmendamos o actualizamos nosotros. "Procesar", significa usar cookies en una computadora / dispositivo móvil o usar o tocar información de cualquier manera, incluyendo, pero no limitado a, recopilar, almacenar, eliminar, usar, combinar y divulgar información, todas las actividades tendrán lugar en servidores de los Estados Unidos. Si reside fuera de los Estados Unidos, su información será transferida, procesada y almacenada allí bajo los estándares de privacidad de los Estados Unidos.</p>

					<h4 className="bold-font subtitle">7. Tus derechos</h4>

					<p className="parrafo-black">Si quieres oponerte al procesamiento de tus datos de acuerdo con esta política de privacidad en su conjunto, puedes detener fácilmente toda la recopilación de información por la aplicación desinstalándola. Puedes utilizar los procesos de desinstalación estándar que estén disponibles como parte de tu dispositivo móvil o a través del mercado o la tienda de aplicaciones móviles correspondiente. Ten en cuenta que retendremos los datos proporcionados por un usuario durante el tiempo que utilice la aplicación y durante un tiempo razonable después de esto.</p>

					<h4 className="bold-font subtitle">8. Modificación de la política de privacidad</h4>

					<p className="parrafo-black">Nos reservamos el derecho a modificar la presente política de privacidad ocasionalmente por cualquier motivo, publicando las actualizaciones inmediatamente en esta presente dirección web. Se recomienda consultar esta Política de privacidad regularmente para cualquier cambio, ya que el uso continuado se considera aprobación de todos los cambios.</p>


			      </div>
			    </div>  
			  </div>

			</section>
			<Footer/>
		</div>
	)
}
export default Privacidad;